import "./global.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import Auth from "./layouts/auth";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Welcome from "./pages/Welcome";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import ConnectPage from "./pages/ConnectPage";
import SubscriberTableNew from "./pages/SubscriberTableNew";
import SignUpShopify from "./pages/SignUpShopify";
import WelcomeShopify from "./pages/WelcomeShopify";

import DashboardLayout from "./layouts/dashboard";
import CampaignList from "./pages/CampaignList";
import Growth from "./pages/Growth";
import ContactNew from "./pages/ContactNew";
import Settings from "./pages/Settings";
import CampaignCreate from "./pages/CampaignCreate";
import SegmentCreateNew from "./pages/SegmentCreateNew";
import SegmentNew from "./pages/SegmentNew";
import TemplateFormNew from "./pages/TemplateFormNew";
import CampaignDetail from "./pages/CampaignDetail";
import ActivatePage from "./pages/ActivatePage";

import AuthGuard from "./auth-guard/AuthGuard";
import GuestGuard from "./auth-guard/GuestGuard";

import FacebookSDKInitializer from "./helpers/FacebookSDKInitializer";
import { PageProvider } from "./context/pageContext";
import { AuthProvider } from "./context/authContext";
import SnackbarProvider from "./components/snackbar/snackbar-provider";
import LocalizationProvider from "./context/localizationProvider";
import ThemeProvider from "./theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <FacebookSDKInitializer />
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <LocalizationProvider>
          <ThemeProvider>
            <SnackbarProvider>
              <PageProvider>
                <BrowserRouter>
                  <Routes>
                    <Route
                      element={
                        <GuestGuard>
                          <Auth />
                        </GuestGuard>
                      }
                    >
                      <Route path="/" element={<Login />} />
                      <Route path="signup" element={<SignUp />} />
                      <Route
                        path="forgot-password"
                        element={<ForgotPassword />}
                      />
                      <Route
                        path="reset-password/:token"
                        element={<ResetPassword />}
                      />
                      <Route
                        path="shopify-signup"
                        element={<SignUpShopify />}
                      />
                      <Route path="connect" element={<ConnectPage />} />
                    </Route>

                    <Route
                      element={
                        <AuthGuard>
                          <Auth />
                        </AuthGuard>
                      }
                    >
                      <Route path="welcome" element={<Welcome />} />
                      <Route
                        path="shopify-welcome"
                        element={<WelcomeShopify />}
                      />
                    </Route>

                    <Route
                      path="app"
                      element={
                        <AuthGuard>
                          <DashboardLayout />
                        </AuthGuard>
                      }
                    >
                      <Route index element={<Navigate replace to="home" />} />
                      <Route path="home" element={<CampaignCreate />} />

                      <Route path="subscribers">
                        <Route index element={<SubscriberTableNew />} />
                        <Route path=":contactId" element={<ContactNew />} />
                      </Route>

                      <Route path="campaign">
                        <Route index element={<CampaignList />} />
                        <Route path=":broadcastId">
                          <Route
                            index
                            element={<Navigate replace to="overview" />}
                          />
                          <Route path="overview" element={<CampaignDetail />} />
                          <Route
                            path="recipient"
                            element={<CampaignDetail />}
                          />
                        </Route>
                      </Route>

                      <Route path="growth" element={<Growth />} />

                      <Route path="settings">
                        <Route
                          index
                          element={<Navigate replace to="account" />}
                        />
                        <Route path="account" element={<Settings />} />
                        <Route path="billing" element={<Settings />} />
                        <Route path="integration" element={<Settings />} />
                      </Route>

                      <Route path="segment">
                        <Route index element={<SegmentNew />} />
                        <Route path="create" element={<SegmentCreateNew />} />
                      </Route>

                      <Route path="template" element={<TemplateFormNew />} />

                      <Route path="activation" element={<ActivatePage />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </PageProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
