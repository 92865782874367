import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo, useState, useEffect, useCallback, useContext } from "react";
import PageContext from "../context/pageContext";
import { useQueryClient } from "@tanstack/react-query";
import { fDate } from "../helpers/format-time";

import { getSubscriberCount, getSegments } from "../helpers/apiSegment";
import { sendBroadcast, sendPreview } from "../helpers/apiBroadcast";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";

import UploadWidget from "../components/UploadWidget";
import { useSnackbar } from "../components/snackbar";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";

import { useRouter, useResponsive, useBoolean } from "../hooks";

import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFDateTimePicker,
} from "../components/hook-form";
import FilePreview from "../components/FilePreview";

import { IPageContext } from "../types/page.type";

// ----------------------------------------------------------------------

interface SegmentType {
  segmentId: string;
  pageId: string;
  segmentName: string;
  isList: boolean;
  queryString: Record<string, any>;
  contactCount: number;
  createDate: Date;
}

export default function CampaignCreate() {
  const router = useRouter();

  const mdUp = useResponsive("up", "md");

  const { enqueueSnackbar } = useSnackbar();

  const formSubmitted = useBoolean();
  const preview = useBoolean();
  const showButton = useBoolean();
  const isScheduled = useBoolean();

  const queryClient = useQueryClient();

  const [pageSegments, setPageSegments] = useState<SegmentType[]>([]);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [uploadedFile, setUploadedFile] = useState<{
    fileName: string;
    fileFormat: string;
  } | null>(null);

  const { pageId, pageCompliance } = useContext(PageContext) as IPageContext;
  // TODO need to change compliance check as this is not the right way
  const formDisabled = pageCompliance?.compliance === 3;
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    setTimeout(() => window.FB.XFBML.parse(), 50);
  }, [preview]);

  const BroadcastSchema = Yup.object().shape({
    broadcastName: Yup.string().required("Name is required"),
    broadcastText: Yup.string().required("Text is required"),

    // Conditional Validations
    buttonPrompt: Yup.string().when("$showButton", (showButton, schema) =>
      showButton[0] ? schema.required("Button title is required") : schema
    ),
    buttonUrl: Yup.string().when("$showButton", (showButton, schema) =>
      showButton[0] ? schema.required("Button Url is required") : schema
    ),

    broadcastTime: Yup.date().when("$isScheduled", (isScheduled, schema) =>
      isScheduled[0]
        ? schema.min(new Date(), "Scheduled time must be in the future")
        : schema
    ),
    // not required
    fileType: Yup.string(),
    fileLink: Yup.string(),
    segmentId: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      broadcastName: "Campaign - " + fDate(new Date(), "MMM dd, yyyy") || "",
      broadcastTime: new Date(),
      broadcastText: "",
      pageId: pageId,
      fileType: "",
      fileLink: "",
      buttonPrompt: "",
      buttonUrl: "",
      userId: userId,
      segmentId: "",
      isFlow: false,
    }),
    [pageId, userId]
  );

  const methods = useForm({
    resolver: yupResolver(BroadcastSchema),
    defaultValues,
    context: { showButton: showButton.value, isScheduled: isScheduled.value },
  });

  const {
    watch,
    setValue,
    handleSubmit,
    resetField,
    control,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    const getSegmentsAsync = async () => {
      try {
        const { data }: any = await getSegments({
          pageId,
          page: 1,
          PAGE_SIZE: 100,
        });
        setPageSegments(data.segments);
      } catch (error) {}
    };

    getSegmentsAsync();
  }, [pageId]);

  useEffect(() => {
    const getSubscriberCountAsync = async () => {
      try {
        const { count } = await getSubscriberCount({
          pageId,
          segmentId: values.segmentId || "",
        });
        setSubscriberCount(count);
      } catch (error) {}
    };
    getSubscriberCountAsync();
  }, [pageId, values.segmentId]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const buttonArray = !!data.buttonPrompt
        ? [{ buttonPrompt: data.buttonPrompt, buttonUrl: data.buttonUrl }]
        : [];

      const { status, message } = await sendBroadcast({
        ...data,
        isScheduled: isScheduled.value,
        buttonArray,
      });
      if (status === "fail") {
        console.error(message);
      } else if (status === "success") {
        queryClient.invalidateQueries({ queryKey: ["broadcasts"] });
        formSubmitted.onTrue();
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handlePreview = handleSubmit(async (data) => {
    try {
      const formData = {
        ...data,
        isScheduled: isScheduled.value,
        buttonArray: !!data.buttonPrompt
          ? [
              {
                buttonPrompt: data.buttonPrompt,
                buttonUrl: data.buttonUrl,
              },
            ]
          : [],
      };

      const previewResponse = await sendPreview(formData);
      if (
        previewResponse.status === "error"
        // @note could either 24 hrs passed or never connected contact w/ user
        // &&
        // previewResponse.message ===
        //   "(#10) This message is being sent outside the allowed window. Learn more about the new policy here: https://developers.facebook.com/docs/messenger-platform/policy-overview"
      ) {
        preview.onToggle();
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleAddButtonToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        resetField("buttonPrompt");
        resetField("buttonUrl");
      }
      showButton.setValue(event.target.checked);
    },
    [resetField, showButton]
  );

  const handleScheduleScheduleToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        resetField("broadcastTime");
      }
      isScheduled.setValue(event.target.checked);
    },
    [resetField, isScheduled]
  );

  function handleOnUpload(error: any, result: any, widget: any) {
    if (error) {
      enqueueSnackbar(error.statusText, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      widget.close({
        quiet: true,
      });
      return;
    }
    setUploadedFile({
      fileName: result.info["original_filename"],
      fileFormat: result.info["format"],
    });
    setValue("fileType", result.info["resource_type"], {
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("fileLink", result.info["secure_url"], {
      shouldDirty: true,
      shouldTouch: true,
    });
  }

  const handleRemoveFile = useCallback(() => {
    // Not sure why resetField is not working
    setValue("fileType", "");
    setValue("fileLink", "");
    setUploadedFile(null);
  }, [setValue, setUploadedFile]);

  // Listens for opt-in event
  // @NOTE When send to messenger is clicked in succession, it will trigger the opt-in event compoundedly. Also form data is not updated in initial versions. This issue is avoided at handlePreview function.
  // @ts-ignore
  window.FB.Event.subscribe("send_to_messenger", async function (e) {
    // @ts-ignore
    if (e.event === "opt_in" && preview.value) {
      console.log(e);

      const formData = {
        ...values,
        isScheduled: isScheduled.value,
        buttonArray: !!values.buttonPrompt
          ? [
              {
                buttonPrompt: values.buttonPrompt,
                buttonUrl: values.buttonUrl,
              },
            ]
          : [],
      };

      await sendPreview(formData);
      preview.onFalse();
    }
  });

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            What your subscribers will see
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField
              name="broadcastName"
              label="Campaign Name *"
              InputLabelProps={{ shrink: true }}
              disabled={formDisabled}
            />

            <Stack spacing={0}>
              <UploadWidget onUpload={handleOnUpload} disabled={formDisabled} />
              {uploadedFile && (
                <Box>
                  <FilePreview
                    file={uploadedFile}
                    onRemove={handleRemoveFile}
                  />
                </Box>
              )}
            </Stack>

            <Stack spacing={0.5}>
              <RHFTextField
                name="broadcastText"
                label="Text Input *"
                inputProps={{ maxLength: 250 }}
                InputLabelProps={{ shrink: true }}
                multiline
                minRows={5}
                disabled={formDisabled}
              />
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Characters: {values.broadcastText.length}/250
              </Typography>
            </Stack>
            <Stack spacing={1.5}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showButton.value}
                    onChange={handleAddButtonToggle}
                    disabled={formDisabled}
                  />
                }
                label="+ Add Button"
              />

              {showButton.value && (
                <>
                  <Stack spacing={1}>
                    <RHFTextField
                      name="buttonPrompt"
                      label="Button Title"
                      inputProps={{ maxLength: 20 }}
                      InputLabelProps={{ shrink: true }}
                      disabled={formDisabled}
                    />
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Characters: {values.buttonPrompt?.length ?? 0}
                      /20
                    </Typography>
                  </Stack>

                  <RHFTextField
                    name="buttonUrl"
                    label="Button URL Link"
                    InputLabelProps={{ shrink: true }}
                    disabled={formDisabled}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );
  const renderRecipient = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Recipient
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Who gets this message and when
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Recipient" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={0.5}>
              <RHFSelect
                native
                name="segmentId"
                label="Segments"
                InputLabelProps={{ shrink: true }}
                disabled={formDisabled}
              >
                <option value="All">All</option> {/* Add "All" option */}
                {pageSegments.map((segment) => (
                  <option key={segment.segmentId} value={segment.segmentId}>
                    {segment.segmentName}
                  </option>
                ))}
              </RHFSelect>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                This campaign would be sent to approximately {subscriberCount}{" "}
                {subscriberCount === 1 ? ` person.` : ` people.`}
              </Typography>
            </Stack>

            <Stack spacing={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isScheduled.value}
                    onChange={handleScheduleScheduleToggle}
                    disabled={formDisabled}
                  />
                }
                label="Schedule Send"
              />
              {isScheduled.value && (
                <>
                  <Typography
                    variant="body2"
                    sx={{ pb: 1, color: "text.secondary" }}
                  >
                    Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}
                  </Typography>
                  <RHFDateTimePicker
                    name="broadcastTime"
                    label="Scheduled Time"
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Stack>
        <Grid
          xs={12}
          md={10}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={formDisabled || isSubmitting}
            sx={{ mr: 1 }}
          >
            {!isScheduled.value ? "Send Now" : "Schedule"}
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            size="large"
            color="primary"
            onClick={handlePreview}
            disabled={formDisabled || isSubmitting}
            sx={{ mr: 1 }}
          >
            Preview
          </LoadingButton>
          {isSubmitting && <CircularProgress />}
        </Grid>
        <Typography variant="body2" sx={{ ml: 1, color: "text.secondary" }}>
          ⚠ Meta restricts 1 non-preview broadcast per 24 hours
        </Typography>
      </Stack>
    </>
  );

  const renderPreviewDialog = (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={preview.value}
      onClose={preview.onFalse}
    >
      <DialogTitle sx={{ pb: 0 }}>Almost There</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ py: 4 }}>
          <Typography variant="body1">
            Click "Send to Messenger" to preview the broadcast
          </Typography>
          <div
            //@ts-ignore - not sure how to set types for FB plugin
            messenger_app_id={process.env.REACT_APP_FB_APP_ID}
            className="fb-send-to-messenger"
            page_id={pageId}
            color="blue"
            size="large"
            data-ref={"prev-" + userId}
          ></div>

          <Typography variant="caption">
            We only ask you this when your last interaction with the page is
            more than 24 hours ago to keep compliant with Meta's messaging
            policies.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );

  const renderConfirmation = (
    <Card>
      <CardHeader title="Your campaign is waiting to go" />
      <Stack spacing={3} sx={{ p: 3 }}>
        <Typography variant="body1">
          <strong>{values.broadcastName}</strong> is queued up and waiting to be
          sent.
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => router.push("/app/campaign")}
          sx={{
            p: 1,
            width: "145px",
          }}
        >
          Go to campaigns
        </Button>
      </Stack>
    </Card>
  );
  return (
    <Container maxWidth="lg">
      <Stack
        spacing={2}
        direction="column"
        alignItems="left"
        sx={{
          mb: { xs: 3, md: 5 },
          padding: "1rem",
        }}
      >
        <CustomBreadcrumbs heading="Home" links={[]} />
        {formSubmitted.value === false ? (
          <>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Grid container spacing={3}>
                {renderDetails}
                {renderRecipient}
                {renderActions}
              </Grid>
              {renderPreviewDialog}
              <DevTool control={control} />
            </FormProvider>
          </>
        ) : (
          <>{renderConfirmation}</>
        )}
      </Stack>
    </Container>
  );
}
